export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboards.text",
        icon: "bx-home-circle",
        link: "/ss/admin/dashboard",
        
    },
    
    {
        id: 3,
        label: "menuitems.simulation.text",
        icon: "bx bx-list-ul",
        link: "/ss/admin/payment/simulation",
        
    },
];

